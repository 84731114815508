import React from 'react';
import { FaGithub, FaLinkedin, FaEnvelope, FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa';
import './style.css';

export default function ContactCard() {
  return (
    <div id='contactCardContainer'>

      <div id='contactInfoContainer'>
        <div id='contactInfo'>
          <p id='infoName'>High Times 4 Low Times</p>
          {/* <p id='infoTitle' className='text-center'>Britt Bot 
          <span className='symbol'> + </span> 
          Amanda</p> */}
        </div>
        <div id='contactIconContainer'>
          <a href="mailto:email@ht4lt.com"><FaEnvelope /><span className='contactText'>Email</span></a>
          {/* <a href="https://github.com/britt-bot"><FaGithub /><span className='contactText'>GitHub</span></a>
          <a href="https://www.linkedin.com/in/brittbot"><FaLinkedin /><span className='contactText'>LinkedIn</span></a> */}
          <a href="https://www.facebook.com/hightimesforlowtimes"><FaFacebook /><span className='contactText'>Facebook</span></a>
          <a href="https://www.instagram.com/hightimesforlowtimes"><FaInstagram /><span className='contactText'>Instagram</span></a>
          <a href="https://twitter.com/Htimes4Ltimes"><FaTwitter /><span className='contactText'>Twitter</span></a>
        </div>
      </div>

      <div id='contactImgContainer'>
        {/* <h3 id='collaborate' className='text-center'>Let's Collaborate!</h3> */}
        <img src='assets/images/jacketcircleBLU.png' alt='Collaborate' id='contactImg'/>
      </div>
    </div>
  )
};