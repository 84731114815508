import React from 'react';
import ImageContainer from '../ImageContainer/ImageContainer';
import './style.css'

export default function AboutInfo() {
  return (
    <div id='aboutInfo'>
      <div id='aboutImgContainerLeft'>
        <ImageContainer id='aboutImgB' src='assets/images/brittCIR.png' alt='Host Britt Image'/>
      </div>
      <div id='info'>
        <h2 className='text-center border'>We are <span id="hightimes">High Times 4 Low Times</span></h2>
        {/* <br /><h5 className='text-center'>We are all about enjoying the highs while laughing and talking about the lows of life.</h5> */}
        <p className='text-center'>
          <br />
          <span id='hightimes'>High Times 4 Low Times</span> is just two best friends enjoying the high while trying to survive and laugh 
          about the lows of life; a podcast hosted by <span id='bname'>Britt Bot</span> and <span id='aname'>Amanda</span>. 
          <br />
          <br />
          Whether it's losing a parent, being a parent, dating and breakups, or just navigating through life, we try to find the funny in the 
          situations most can't find the humor in. 
          <br />
          <br />
          Basically just two goofy ass besties hanging out that love to talk and laugh, while sharing old stories, new stories and maybe a little 
          self-help here and there.
          <br />
          <br />
          <span id='disclaim'>Disclaimer: We are not licensed doctors, so give us a listen. Just don't take us too seriously.</span>
        </p>
      </div>
      <div id='aboutImgContainerRight'>
        <ImageContainer id='aboutImgA' src='assets/images/amandaCIR.png' alt='Host Amanda Image'/>
      </div>
    </div>
  )
};
